import { Link, useLocation } from "react-router-dom";
import styles from "../../styles/header/style.module.css";

export const CustomLink=({ children, direction })=>{
    const location=useLocation();
    const linkClassName=direction===location.pathname ? styles.linkActive : styles.link;

    return(
        <Link to={direction} className={linkClassName}>
            { children }
        </Link>
    );
}