import styles from '../styles/representative/style.module.css';
import flowerLeft from '../images/flower-left.svg';
import flowerRight from '../images/flower-right.svg';

export const BackgroundFlowers=()=>{
    return(
        <div className={styles.flowersContainer}>
            <img src={flowerLeft} alt='flower-left' className={styles.flowerLeft} draggable='false' />
            <img src={flowerRight} alt='flower-right' className={styles.flowerRight} draggable='false' />
        </div>
    );
}