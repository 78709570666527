import Sea from '../images/sea.png';
import { BackgroundFlowers } from '../components/BackgroundFlowers';
import styles from '../styles/representative/style.module.css';
import { ReviewAndBook } from '../components/ReviewAndBook';

export const Representative=()=>{
    return(
        <div className={styles.container}>
            <div className={styles.containerRepresent}>
                <ReviewAndBook />
                <img src={Sea} alt='Sea' />
            </div>
            <BackgroundFlowers />
        </div>
    );
}