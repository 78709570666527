import { CustomLink } from "./CustomLink";
import { motion } from 'framer-motion';
import styles from '../../styles/header/style.module.css';

export const Header=()=>{
    const links={
        names: ['Номера', 'Бронирование', 'Галерея', 'Отзывы'],
        directions: ['/в-разработке', '/в-разработке', '/в-разработке', '/reviews']
    };

    return(
        <header className={styles.header}>
            <motion.div
                className={styles.guestHouseName}
                initial={{ opacity: 0, y: -50 }} 
                animate={{ opacity: 1, y: 0 }}   
                transition={{ duration: 0.8, ease: "easeOut" }} 
            >
                SVETLANA GUEST HOUSE
            </motion.div>

            <nav className={styles.navigation}>
                {
                    links.names.map((el, i)=>(
                        <motion.div
                            key={el}
                            initial={{ opacity: 0, x: -30 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{
                                delay: 0.2 * i,  
                                duration: 0.8,
                                ease: "easeOut"
                            }}
                        >
                            <CustomLink direction={links.directions[i]}>
                                {el.toUpperCase()}
                            </CustomLink>
                        </motion.div>
                    ))
                }
            </nav>

            <motion.a
                href="tel:+79183160032"
                className={styles.tel}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, ease: "easeOut", delay: 1 }}
            >
                +7 (918) 316-00-32
            </motion.a>
        </header>
    );
}
