import { Suspense, lazy, useEffect, useState } from 'react';
import { BackgroundFlowers } from '../components/BackgroundFlowers';
import { ReviewAndBook } from '../components/ReviewAndBook';
import styles from '../styles/reviews/style.module.css';
import axios from 'axios';
import { MdOutlineStarPurple500 } from "react-icons/md";

const LazyModal=lazy(()=>import('../portals/LeaveReview'));

export const Reviews=()=>{
    const [reviews, setReviews]=useState([]);
    const [openModal, setOpenModal]=useState(false);

    useEffect(()=>{
        axios.get('https://svetlana-guest-house.com/server/get_reviews.php')
        .then(res=>{
            if (Array.isArray(res.data)){
                setReviews(res.data);
            }
            console.log(res.data);
        })
        .catch(err=>console.error(err));
    }, []);

    function handleReview(){
        setOpenModal(true);
    }

    function handleSetColorMark(starNumber, mark){
        return starNumber<=mark ? "black" : "grey"
    }

    return(
        <div className={styles.container}>
            <div className={styles.containerBookAndReviews}>
                <ReviewAndBook />
                <section className={styles.reviews}>
                    {
                        reviews.length>0 ? Array.isArray(reviews) && reviews.map(review=>{
                            return(
                                <div className={styles.comment} key={review.name}>
                                    <div>
                                        <span>{review.name}</span>
                                        <div>
                                            {
                                                [...Array(5)].map((_, i)=>{
                                                    return <MdOutlineStarPurple500 
                                                        className={styles.star}
                                                        style={{ color: handleSetColorMark(i, review.stars) }}
                                                    />
                                                })
                                            }
                                        </div>
                                    </div>    
                                    <span className={styles.review}>{review.review}</span>
                                </div>
                            );
                        }) : <span className={styles.noReviews}>Ещё нет отзывов</span>
                    }
                    <button onClick={handleReview}>
                        Оставить отзыв
                    </button>
                </section>
            </div>
            <BackgroundFlowers />
            {
                openModal && 
                <Suspense>
                    <LazyModal setOpenModal={setOpenModal} />
                </Suspense>
            }
        </div>
    );
}