import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/Layout";
import { Representative } from "./pages/Representative";
import styles from "./styles/app.module.css";
import { Suspense, lazy } from "react";
import { Reviews } from "./pages/Reviews";

const Lazy404=lazy(()=>import('./pages/NotFound'));

function App(){
  return(
    <div className={styles.app}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Representative />} />
            <Route path="/reviews" element={<Reviews />} />
          </Route>
          <Route path="*" 
          element={
            <Suspense>
              <Lazy404 />
            </Suspense>
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
