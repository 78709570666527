import { Outlet } from "react-router-dom";
import { Header } from "./header_components/Header";
import styles from '../styles/layout/style.module.css';

export const Layout=()=>{
    return(
        <main className={styles.layout}>
            <Header />
            <Outlet />
        </main>
    );
}