import styles from '../styles/representative/style.module.css';
import stylesRev from '../styles/reviews/style.module.css';
import { BsStars } from "react-icons/bs";
import { useLocation } from 'react-router-dom';

export const ReviewAndBook=()=>{
    const location=useLocation().pathname;

    function handleBook(){
        alert('В разработке');
    }

    return(
        <div className={`${styles.descriptionAndBooking} ${stylesRev.descriptionAndBooking}`}>
            {
                location==='/' ? 
                <span>
                    бронируйте хорошие номера
                    для лучших впечатлений
                    об отдыхе <BsStars />
                </span> : 
                location==='/reviews' ?
                <span>
                    много довольных гостей.
                    большое количество
                    отзывов
                </span> : ''
            }
            <button className={styles.book} onClick={handleBook}>БРОНИРОВАТЬ</button>
        </div>
    );
}